import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { HeaderTitleMolecule } from "../../molecules";
import { GradientHR } from "../shared/shared.page.style";
import { PurchaseOrderOrganism } from "../../organisms/purchase-order-list/purchase-order-list.organism";

export const PurchaseOrderListPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <HeaderTitleMolecule
        title={t("OrderList")}
        returnButtonActionOverride={() => history.push(paths.myStock())}
        navigationButtonText={t("BuyCredits")}
        haveButtonsCreditStatementAndOrderList={true}
      />

      <GradientHR />

      <PurchaseOrderOrganism />
    </>
  );
};
