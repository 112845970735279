export enum RenaveMenuStepsEnum {
  Aptness = 1,
  CheckInVehicle,
  CheckInDanfe,
  CheckedIn,
  CheckOutVehicle,
  CheckOutDanfe,
  CheckedOut,
}

export enum RenaveListStepsEnum {
  AptnessRestriction = 15, // -> Aptidão com restrição,
  Aptness = 1, // -> Entrada veículo
  CheckInVehicle, // -> Danfe de entrada
  CheckInDanfe, // -> Aguardando Detran
  CheckedIn, // -> Em estoque
  CheckOutVehicle, // -> Danfe de saída
  CheckOutDanfe, // Aguardando transferência/venda
  CheckedOut, // -> Fora de estoque
  Cancelled, // -> Cancelado
  PendingZeroKm, // -> Entrada veículo 0 km
  RejectedZeroKm, // Rejeitado 0km
  CheckedInZeroKm, // -> Em estoque 0km
  CheckOutVehicleZeroKm, // -> Aguardando transferência/venda 0km
  CheckedOutZeroKm, // -> Fora de estoque 0km
  ReturnedZeroKm, // Retornado para a montadora
}

export enum RenaveMenuStepsZeroKmEnum {
  CheckInVehicleZeroKm = 1,
  CheckedInZeroKm,
  CheckOutVehicleZeroKm,
  CheckedOutZeroKm,
}

export enum RenaveVehicleZeroKm {
  Cancelleda = 8,
  PendingZeroKm = 9,
  RejectedZeroKm = 10,
  CheckedInZeroKm = 11,
  CheckOutVehicleZeroKm = 12,
  CheckedOutZeroKm = 13,
  ReturnedZeroKm = 14,
}

export enum RenaveVehicleUsed {
  Aptness = 1,
  CheckedIn = 4,
  CheckedOut = 7,
  AptnessRestriction = 15,
  CheckInVehicle = 2,
  CheckInDanfe = 3,
  CheckOutVehicle = 5,
  CheckOutDanfe = 6,
}
