import {
  ButtonPrimary,
  CoxIcon,
  DateHelper,
  DownloadFileAtom,
  FilterAtom,
  theme,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LocalStorageKeys } from "../../../../constants";
import { RenaveStatusHelper } from "../../../../helper";
import { useRenave, useSessionContext } from "../../../../hooks";
import {
  GetVehicleRequest,
  IGetSummaryResponse,
  IGetVehicleRequest,
  IGetVehicleResponse,
  IPagedResponseBase,
} from "../../../../models";
import { FormikControlRenaveAtom } from "../../../atoms";
import { FormikControlCalendarAtom } from "c4u-web-components";
import { StatusSummaryMolecule } from "../../../molecules";
import {
  CheckboxWrapper,
  CleanFiltersDiv,
  ErrorWrapper,
  FormCheckboxWrapper,
  MenuIconsWrapper,
  MyStockHeaderWrapper,
  RotatableClickableDiv,
} from "./my-stock-header.organism.style";
import { MyStockDataFormValidation } from "./my-stock-header.validation";

interface IProps {
  setMyVehicles: (v: IGetVehicleResponse[] | undefined) => void;
  setPaginationData: (v: IPagedResponseBase | undefined) => void;
  setPageNumber?: (v: number, isFilter?: boolean) => void;
  goUpdate: boolean;
  setGoUpdate: (v: boolean) => void;
  setFilterValues?: (v: IGetVehicleRequest | undefined) => void;
}

export const MyStockHeaderOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { handleFormikException } = useSessionContext();
  const { getRenaveStock, getSummary, getStockReport } = useRenave();

  const {
    setMyVehicles: setMyVehiclesProps,
    setPaginationData: setPaginationDataProps,
    setPageNumber,
    setFilterValues
  } = props;

  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [summaryData, setSummaryData] = useState<IGetSummaryResponse>();
  const [vehicleCheckedUsed, setVehicleCheckedUsed] = useState(true);
  const [vehicleCheckedZeroKm, setVehicleCheckedZeroKm] = useState(true);

  const initialValues = useMemo<IGetVehicleRequest>(
    () => ({
      search: "",
      page: 1,
      pageSize: 10,
      startDate: "",
      finishDate: "",
      step: "",
      isUsed: true,
      isZeroKm: true,
    }),
    []
  );

  const formik = useFormik<IGetVehicleRequest>({
    initialValues: {
      ...initialValues,
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        setMyVehiclesProps(undefined);
        const updateZeroKm =
          localStorage.getItem(LocalStorageKeys.UpdateZeroKm) === "true";
        const request = new GetVehicleRequest({
          ...values,
          page: 1,
          updateZeroKm,
        });

        const { data, ...paginationData } = await getRenaveStock(request);
        setMyVehiclesProps(data);
        setPaginationDataProps(paginationData);
        if (setFilterValues) setFilterValues(values);
        if (setPageNumber) setPageNumber(1, true);
        formik.setFieldValue("page", 1);

        if (updateZeroKm) {
          localStorage.setItem(LocalStorageKeys.UpdateZeroKm, "false");
        }
      } catch (e) {
        handleFormikException(e, setErrors);
        setMyVehiclesProps([]);
      }
    },
    validationSchema: MyStockDataFormValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  const handleCleanFilters = useCallback(() => {
    formik.setValues(initialValues);
    setVehicleCheckedUsed(true);
    setVehicleCheckedZeroKm(true);
  }, [formik, initialValues]);

  useEffect(() => {
    if (!filterIsOpen) handleCleanFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterIsOpen]);

  const handleUpdateSummary = useCallback(() => {
    getSummary()
      .then((data) => setSummaryData(data))
      .catch((e) => console.log("Erro ao atualizar o resumo", e));
  }, [getSummary]);

  useEffect(() => {
    handleUpdateSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.goUpdate && !formik.isSubmitting) {
      setSummaryData(undefined);
      handleUpdateSummary();
      setMyVehiclesProps(undefined);
      setPaginationDataProps(undefined);
      formik.handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goUpdate]);

  return (
    <>
      <MyStockHeaderWrapper>
        <Row>
          <Col lg={12}>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Row>
                <FormikControlRenaveAtom
                  formik={formik}
                  type="search-submit"
                  property="search"
                  sm={6}
                  md={7}
                  lg={8}
                />
                <Col sm={6} md={4}>
                  <MenuIconsWrapper>
                    <FilterAtom
                      filterText={t("Filter")}
                      filterIsOpen={filterIsOpen}
                      setFilterIsOpen={setFilterIsOpen}
                      color={theme.colors.BlueNavy}
                    />
                    <DownloadFileAtom
                      getFileParams={undefined}
                      getFileCallback={getStockReport}
                      iconName="excel"
                      fileName={`Estoque Renave ${DateHelper.dateHourCustomFormat(
                        new Date(),
                        "dd-MM-yyyy"
                      )}.csv`}
                      htmlTitle={t("MyStockDownload")}
                    />
                    <RotatableClickableDiv
                      isLoading={formik.isSubmitting}
                      onClick={
                        formik.isSubmitting
                          ? undefined
                          : () => {
                              props.setGoUpdate(true);
                              localStorage.setItem(
                                LocalStorageKeys.UpdateZeroKm,
                                "true"
                              );
                            }
                      }
                      title={t("Update")}
                    >
                      <CoxIcon name="reload" />
                    </RotatableClickableDiv>
                  </MenuIconsWrapper>
                </Col>
              </Form.Row>

              {filterIsOpen && (
                <>
                  <Form.Row className="d-flex align-items-end">
                    <FormikControlCalendarAtom
                      label={t("StartDate")}
                      property="startDate"
                      formik={formik}
                      xs={6}
                      lg={2}
                      translate={t}
                    />
                    <FormikControlCalendarAtom
                      label={t("FinishDate")}
                      property="finishDate"
                      formik={formik}
                      xs={6}
                      lg={2}
                      translate={t}
                    />
                    <CheckboxWrapper>
                      <FormCheckboxWrapper error={!!formik.errors["isZeroKm"]}>
                        <Form.Check
                          type="checkbox"
                          label={t("ZeroKmState")}
                          onChange={(e) => {
                            formik.setFieldValue("isZeroKm", e.target.checked);
                            setVehicleCheckedZeroKm(e.target.checked);
                          }}
                          checked={vehicleCheckedZeroKm}
                          isInvalid={!!formik.errors["isZeroKm"]}
                        />
                        <Form.Check
                          type="checkbox"
                          label={t("UsedState")}
                          onChange={(e) => {
                            formik.setFieldValue("isUsed", e.target.checked);
                            setVehicleCheckedUsed(e.target.checked);
                          }}
                          checked={vehicleCheckedUsed}
                          isInvalid={!!formik.errors["isZeroKm"]}
                        />
                      </FormCheckboxWrapper>
                      <ErrorWrapper>
                        <Form.Control.Feedback
                          type="invalid"
                          className={
                            !!formik.errors["isZeroKm"]
                              ? "d-flex justify-content-center p-0 m-0"
                              : "d-none"
                          }
                        >
                          {formik.errors["isZeroKm"]}
                        </Form.Control.Feedback>
                      </ErrorWrapper>
                    </CheckboxWrapper>

                    <FormikControlRenaveAtom
                      type={"select"}
                      data={RenaveStatusHelper.getStatusCombos(
                        t,
                        vehicleCheckedUsed,
                        vehicleCheckedZeroKm
                      )}
                      label={t("Status")}
                      property={"step"}
                      formik={formik}
                      placeholder={t("AllStatus")}
                      sm={6}
                      lg={3}
                    />
                    <Form.Group
                      as={Col}
                      xs={{ span: 4, offset: 1 }}
                      sm={{ span: 2, offset: 0 }}
                      md={2}
                      xl={1}
                      className="pb-2 text-center"
                    >
                      <ButtonPrimary
                        type="submit"
                        sizex="sm"
                        sizey="thin"
                        loading={formik.isSubmitting}
                      >
                        {t("OK")}
                      </ButtonPrimary>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={4} lg={2} className="pb-2">
                      <CleanFiltersDiv
                        onClick={
                          formik.isSubmitting ? undefined : handleCleanFilters
                        }
                      >
                        {t("CleanFilters")}
                      </CleanFiltersDiv>
                    </Form.Group>
                  </Form.Row>
                </>
              )}

              <StatusSummaryMolecule summaryData={summaryData} />
            </Form>
          </Col>
        </Row>
      </MyStockHeaderWrapper>
    </>
  );
};
