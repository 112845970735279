import { CoxIcon, DateHelper, NumberHelper } from "c4u-web-components";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  CreditPackTypeEnum,
  IGetPurchaseOrderResponse,
  PaymentMethodEnum,
  PurchaseStatusEnum,
} from "../../../models";
import {
  ButtonPaymentMethod,
  OrderListNegativeMoneyText,
  OrderListTextMargin,
  OrderListTextPrimary,
  OrderListTextSecond,
  OrderListWrapper,
  StatusIndicator,
} from "./purchase-order-list-data.molecule.style";
import { useSessionContext } from "../../../hooks";

interface IProps {
  purchaseOrderData?: IGetPurchaseOrderResponse;
}

const getStatusColor = (status: PurchaseStatusEnum) => {
  switch (status) {
    case PurchaseStatusEnum.Pending:
      return "#EEA200";
    case PurchaseStatusEnum.Approved:
      return "#4AA434";
    case PurchaseStatusEnum.Refused:
      return "#C4390A";
    default:
      return "black";
  }
};

export const PurchaseOrderListMolecule: React.FC<IProps> = ({
  purchaseOrderData,
}) => {
  const { t } = useTranslation();
  const { showGenericErrorModal } = useSessionContext();

  const handleBankSpliClick = (dueDate: string, bankSlipURL: string) => {
    const date = new Date(dueDate);
    const dateNow = new Date();
    if (date >= dateNow) {
      window.open(bankSlipURL, "_blank", "noopener,noreferrer");
    } else {
      showGenericErrorModal(t("ExpiredBankSplit"), null);
    }
  };

  return (
    <Col>
      <OrderListWrapper>
        <Row className="mt-3 text-center">
          {purchaseOrderData === undefined ? (
            <Col sm={12}>
              <OrderListTextSecond>
                {t("NoTransactionsFound")}
              </OrderListTextSecond>
            </Col>
          ) : (
            <>
              <Col sm={2}>
                <OrderListTextMargin>
                  {purchaseOrderData.controlStockOrderId}
                </OrderListTextMargin>
              </Col>

              <Col sm={1}>
                <OrderListTextMargin>
                  {DateHelper.dateHourCustomFormat(
                    purchaseOrderData.orderDate,
                    "dd/MM/yyyy - HH:mm"
                  )}
                </OrderListTextMargin>
              </Col>

              <Col sm={2}>
                <OrderListTextPrimary>
                  {t(CreditPackTypeEnum[purchaseOrderData.purchaseType])}
                </OrderListTextPrimary>
              </Col>

              <Col sm={1}>
                <OrderListTextPrimary>
                  {purchaseOrderData.pack}
                </OrderListTextPrimary>
              </Col>

              <Col sm={3}>
                <OrderListTextMargin>
                  {purchaseOrderData.paymentMethod ===
                  PaymentMethodEnum.bankSlip ? (
                    <>
                      <ButtonPaymentMethod
                        onClick={() =>
                          handleBankSpliClick(
                            purchaseOrderData.dueDate,
                            purchaseOrderData.bankSlipURL
                          )
                        }
                      >
                        <CoxIcon name={"bar-code"} fillColor="#A8AAAC" />
                        {t(PaymentMethodEnum[purchaseOrderData.paymentMethod])}
                      </ButtonPaymentMethod>
                    </>
                  ) : (
                    <>
                      <CoxIcon
                        name={"a-credit-card"}
                        fillColor="#A8AAAC"
                        width={23}
                        height={23}
                      />
                      {t(PaymentMethodEnum[purchaseOrderData.paymentMethod])}
                    </>
                  )}
                </OrderListTextMargin>
              </Col>

              <Col sm={1}>
                <>
                  <OrderListTextMargin>
                    <StatusIndicator
                      status={getStatusColor(purchaseOrderData.status)}
                    />
                    {t(PurchaseStatusEnum[purchaseOrderData.status])}
                  </OrderListTextMargin>
                </>
              </Col>

              <Col sm={2}>
                <OrderListNegativeMoneyText>
                  {t("$")}{" "}
                  {NumberHelper.toFormatString(purchaseOrderData.value, 2)}
                </OrderListNegativeMoneyText>
              </Col>
            </>
          )}
        </Row>
      </OrderListWrapper>
    </Col>
  );
};
