import { SpinnerCenteredAtom } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useRenave } from "../../../hooks";
import {
  IGetPurchaseOrderResponse,
  IPagedResponseBase,
  IPurchaseOrderPagedResponse
} from "../../../models";
import {
  PaginationControlMolecule,
  PurchaseOrderHeaderMolecule,
  PurchaseOrderListMolecule,
  PurchaseOrderSearchFormMolecule
} from "../../molecules";
import {
  OrderListPaginationWrapper,
  OrderListWrapper,
} from "./purchase-order-list.organism.style";

export const PurchaseOrderOrganism: React.FC = () => {
  const recordsPerPage = 10;

  const { purchaseOrdersList } = useRenave();

  const [paginationData, setPaginationData] = useState<IPagedResponseBase>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [purchaseOrder, setPurchaseOrder] =
    useState<IPurchaseOrderPagedResponse>();
  const [paginatedPurchaseOrder, setPaginatedPurchaseOrder] = useState<
    IGetPurchaseOrderResponse[]
  >([]);
  const [goUpdate, setGoUpdate] = useState(false);

  useEffect(() => {
    if (pageNumber && purchaseOrder) {
      setPaginationData({
        currentPage: pageNumber,
        recordsPerPage: recordsPerPage,
        totalPages: purchaseOrder.totalPages,
        totalRecords: purchaseOrder.totalRecords,
      });

      const start = (pageNumber - 1) * recordsPerPage;
      const end = start + recordsPerPage;

      setPaginatedPurchaseOrder(
        purchaseOrder.data ? purchaseOrder.data.slice(start, end) : []
      );
    }
  }, [purchaseOrder, pageNumber]);

  const onStartSearch = useCallback(() => {
    setGoUpdate(true);
    setPageNumber(1);
    setPurchaseOrder(undefined);
    setPaginationData(undefined);
  }, []);

  useEffect(() => {
    if (goUpdate) setGoUpdate(false);
  }, [goUpdate]);

  return (
    <OrderListWrapper>
      <Row>
        <Col lg={7} xl={9}>
          <PurchaseOrderSearchFormMolecule
            getPurchaseOrder={purchaseOrdersList}
            setPurchaseOrder={setPurchaseOrder}
            onStartSearch={onStartSearch}
          />
        </Col>
      </Row>

      <div className="mt-5">
        <PurchaseOrderHeaderMolecule />
      </div>

      {purchaseOrder === undefined ? (
        <SpinnerCenteredAtom classNames="mt-5" />
      ) : purchaseOrder.data && purchaseOrder.data.length === 0 ? (
        <PurchaseOrderListMolecule purchaseOrderData={undefined} />
      ) : (
        <>
          {paginatedPurchaseOrder.map((m, i) => (
            <PurchaseOrderListMolecule
              key={`item-purchase-order=${i}`}
              purchaseOrderData={m}
            />
          ))}

          {paginationData && paginationData.totalPages > 1 && (
            <OrderListPaginationWrapper>
              <PaginationControlMolecule
                paginationData={paginationData}
                setPageNumber={setPageNumber}
              />
            </OrderListPaginationWrapper>
          )}
        </>
      )}
    </OrderListWrapper>
  );
};
