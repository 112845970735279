import { SpinnerCenteredAtom } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useRenave } from "../../../hooks";
import {
  IGetCreditStatementResponse,
  IPagedResponseBase,
} from "../../../models";
import {
  CreditStatementDataListMolecule,
  CreditStatementHeaderMolecule,
  CreditStatementSearchFormMolecule,
  PaginationControlMolecule,
} from "../../molecules";
import {
  CreditStatementPaginationWrapper,
  CreditStatementWrapper,
} from "./credit-statement.organism.style";

export const CreditStatementOrganism: React.FC = () => {
  const recordsPerPage = 10;

  const { getCreditStatement } = useRenave();

  const [paginationData, setPaginationData] = useState<IPagedResponseBase>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [creditStatement, setCreditStatement] =
    useState<IGetCreditStatementResponse[]>();
  const [paginatedCreditStatement, setPaginatedCreditStatement] = useState<
    IGetCreditStatementResponse[]
  >([]);
  const [goUpdate, setGoUpdate] = useState(false);

  useEffect(() => {
    if (pageNumber && creditStatement) {
      setPaginationData({
        currentPage: pageNumber,
        recordsPerPage: recordsPerPage,
        totalPages: Math.ceil(creditStatement?.length / recordsPerPage),
        totalRecords: creditStatement?.length,
      });

      const start = (pageNumber - 1) * recordsPerPage;
      const end = start + recordsPerPage;

      setPaginatedCreditStatement(creditStatement.slice(start, end));
    }
  }, [creditStatement, pageNumber]);

  const onStartSearch = useCallback(() => {
    setGoUpdate(true);
    setPageNumber(1);
    setCreditStatement(undefined);
    setPaginationData(undefined);
  }, []);

  useEffect(() => {
    if (goUpdate) setGoUpdate(false);
  }, [goUpdate]);

  return (
    <CreditStatementWrapper>
      <Row>
        <Col lg={7} xl={9}>
          <CreditStatementSearchFormMolecule
            getCreditStatement={getCreditStatement}
            setCreditStatement={setCreditStatement}
            onStartSearch={onStartSearch}
          />
        </Col>
      </Row>

      <div className="mt-5">
        <CreditStatementHeaderMolecule />
      </div>

      {creditStatement === undefined ? (
        <SpinnerCenteredAtom classNames="mt-5" />
      ) : creditStatement.length === 0 ? (
        <CreditStatementDataListMolecule creditStatementData={undefined} />
      ) : (
        <>
          {paginatedCreditStatement.map((m, i) => (
            <CreditStatementDataListMolecule
              key={`item-credit-statement=${i}`}
              creditStatementData={m}
            />
          ))}

          {paginationData && paginationData.totalPages > 1 && (
            <CreditStatementPaginationWrapper>
              <PaginationControlMolecule
                paginationData={paginationData}
                setPageNumber={setPageNumber}
              />
            </CreditStatementPaginationWrapper>
          )}
        </>
      )}
    </CreditStatementWrapper>
  );
};
