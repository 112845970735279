import { Combo, Translator } from "c4u-web-components";
import { CreditPackTypeEnum } from "../models";
import { PackQuantityConstant } from "../constants";

export class PackHelper {
  static getStatusName(status: CreditPackTypeEnum, t: Translator) {
    switch (status) {
      case CreditPackTypeEnum.transferDetran:
        return t("transferDetran");
      case CreditPackTypeEnum.usedVehicles:
        return t("usedVehicles");
      case CreditPackTypeEnum.zeroKm:
        return t("zeroKm");
      default:
        return t("");
    }
  }

  static getStatusCombos(t: Translator) {
    return Object.entries(CreditPackTypeEnum)
      .filter(([k, _]) => !isNaN(parseInt(k)))
      .map(
        ([k, _]) =>
          new Combo({
            title: this.getStatusName(parseInt(k), t),
            value: k,
          })
      );
  }

  static getPacksCombos() {
    return PackQuantityConstant.map(
      (pack) =>
        new Combo({
          title: pack,
          value: pack,
        })
    );
  }
}
