import { TypesHelper } from "c4u-web-components";
import { CreditPackTypeEnum } from "../../enums/credit-packs.enum";
import { PaymentMethodEnum } from "../../enums/payment-method.enum";
import { PurchaseStatusEnum } from "../../enums/purchase-status.enum";

export interface IGetPurchaseOrderRequest {
  page: number | null;
  pageSize: number | null;
  startDate?: Date | string;
  finishDate?: Date | string;
  purchaseType?: CreditPackTypeEnum | "";
  pack?: number | "";
  paymentMethod?: PaymentMethodEnum | "";
  status?: PurchaseStatusEnum | "";
}

export class GetPurchaseOrderRequest {
  page: number | null;
  pageSize: number | null;
  startDate: string | null;
  finishDate: string | null;
  purchaseType: CreditPackTypeEnum | null;
  pack: number | null;
  paymentMethod: PaymentMethodEnum | null;
  status: PurchaseStatusEnum | null;

  constructor({
    page,
    pageSize,
    startDate,
    finishDate,
    purchaseType,
    pack,
    paymentMethod,
    status,
  }: IGetPurchaseOrderRequest) {
    this.page = TypesHelper.toNumberNull(page);
    this.pageSize = TypesHelper.toNumberNull(pageSize);
    this.startDate = TypesHelper.toStringNull(startDate);
    this.finishDate = TypesHelper.toStringNull(finishDate);
    this.purchaseType = TypesHelper.toNumberNull(
      purchaseType !== "" ? purchaseType : null
    );
    this.pack = TypesHelper.toNumberNull(pack !== "" ? pack : null);
    this.paymentMethod = TypesHelper.toNumberNull(
      paymentMethod !== "" ? paymentMethod : null
    );
    this.status = TypesHelper.toNumberNull(status !== "" ? status : null);
  }
}
