import { ButtonSecondary, ButtonTertiary } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { FeedbackModal } from "../../feedback-modal/feedback-modal.molecule";
import {} from "./bank-slip-modal.molecule.validation";

import {
  ActionButtonsGroup,
  BoxMessage,
  ModalBodyFormRow,
} from "./bank-slip-modal.molecule.style";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../constants";

interface IProps {
  isModalVisible: boolean;
  setIsModalVisible: (v: boolean) => void;
  bankSlipURL: string | undefined;
}

export type BankSlipAcquisitionForm = {
  dueDate: Date | undefined;
};

export const BankSlipModalMolecule: React.FC<IProps> = ({
  isModalVisible,
  setIsModalVisible,
  bankSlipURL,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onHide = () => {
    setIsModalVisible(false);
  };

  return (
    <FeedbackModal
      show={isModalVisible}
      onHide={onHide}
      typeIcon="success"
      size="md"
      hideFooter
      reducePaddingTop
    >
      <ModalBodyFormRow>
        <span>O boleto foi gerado com sucesso!</span>
      </ModalBodyFormRow>

      <ActionButtonsGroup>
        <ButtonTertiary sizex={"md"} onClick={() => history.push(paths.home())}>
          {t("Ok")}
        </ButtonTertiary>
      </ActionButtonsGroup>

      <BoxMessage>
        <span>{t("ProblemOpenBankSplit")}</span>

        <ButtonSecondary
          sizex="md"
          onClick={() => {
            window.open(bankSlipURL, "_blank", "noopener,noreferrer");
          }}
        >
          {t("ViewBankSplit")}
        </ButtonSecondary>
      </BoxMessage>
    </FeedbackModal>
  );
};
