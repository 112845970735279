import { MsgValidation } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { BankSlipAcquisitionForm } from "./bank-slip-modal.molecule";

export const currentDate = new Date();
export const minDueDateLimit = new Date(new Date().setDate(currentDate.getDate() + 1))
export const maxDueDateLimit = new Date(new Date().setDate(currentDate.getDate() + 10))

export const GenerateBankSlipModalFormValidation = () => {
  const { t } = useTranslation();

  const msgRequired = t(MsgValidation.RequiredField);

  return Yup.object<BankSlipAcquisitionForm>({
    dueDate:
     Yup.date()
      .required(msgRequired)
      .min(currentDate, 'A data de vencimento não pode ser inferior a 1 dia')
      .max(maxDueDateLimit, 'A data de vencimento não pode ser superior a 10 dias corridos')
  });
};
