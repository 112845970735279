import { Form } from "react-bootstrap";
import styled from "styled-components";

export const ModalBodyFormRow = styled(Form.Row)`
  margin: 0px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .col {
    padding: 0;
  }

  span {
    font-weight: 700;
    font-size: 20px;
    color: rgb(31, 62, 116);
  }
`;

export const ActionButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const BoxMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 30px 0 0 0;

  span {
    text-align: center;
    text-decoration: underline;
  }
`;
