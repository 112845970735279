import {
  ButtonPrimary,
  DateHelper,
  MaskHelper
} from "c4u-web-components";
import { addDays, differenceInDays } from "date-fns";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSessionContext } from "../../../hooks";
import {
  GetCreditStatementRequest,
  IGetCreditStatementRequest,
  IGetCreditStatementResponse,
} from "../../../models";
import { FormikControlRenaveAtom } from "../../atoms";
import {
  CleanFiltersDiv,
  WrapperSearch,
} from "./credit-statement-search-form.molecule.style";

interface IProps {
  getCreditStatement: (
    request: GetCreditStatementRequest
  ) => Promise<IGetCreditStatementResponse[]>;
  setCreditStatement: (v?: IGetCreditStatementResponse[]) => void;
  onStartSearch: () => void;
}

export const CreditStatementSearchFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { showGenericErrorModal } = useSessionContext();

  const initialValues = useMemo<IGetCreditStatementRequest>(() => {
    const dateNow = new Date();

    return {
      startDate: DateHelper.dateHourCustomFormat(
        addDays(dateNow, -90),
        "yyyy-MM-dd"
      ),
      finalDate: DateHelper.dateHourCustomFormat(dateNow, "yyyy-MM-dd"),
      plate: "",
    };
  }, []);

  const validations = Yup.object<IGetCreditStatementRequest>({
    plate: Yup.string().optional(),
    startDate: Yup.date()
      .required(t("TheStartDateCannotBeEmpty"))
      .test("date-start-small-date-final", " ", function (end) {
        if (!end) return true;
        const start = this.resolve(Yup.ref("finalDate"));
        const diffDays = differenceInDays(start, end);

        return diffDays >= 0;
      })
      .test("period-valid", " ", function (end) {
        if (!end) return true;
        const start = this.resolve(Yup.ref("finalDate"));
        const diffDays = differenceInDays(start, end);

        return diffDays <= 90;
      }),
    finalDate: Yup.date()
      .required(t("TheFinalDateCannotBeEmpty"))
      .test("date-start-small-date-final", t("ValidationDate"), function (end) {
        if (!end) return true;
        const start = this.resolve(Yup.ref("startDate"));
        const diffDays = differenceInDays(end, start);

        return diffDays >= 0;
      })
      .test("period-valid", t("ValidationIntervalDates"), function (end) {
        if (!end) return true;
        const start = this.resolve(Yup.ref("startDate"));
        const diffDays = differenceInDays(end, start);

        return diffDays <= 90;
      }),
  });

  const formik = useFormik<IGetCreditStatementRequest>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        props.onStartSearch();
        const response = await props.getCreditStatement(
          new GetCreditStatementRequest(values)
        );
        props.setCreditStatement(response);
      } catch (e) {
        props.setCreditStatement([]);
        showGenericErrorModal(
          t("ErrorMsgGetCreditStatement"),
          null,
          t("Error")
        );
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const handleCleanFilters = useCallback(() => {
    formik.setValues(initialValues);
    formik.submitForm();
  }, [formik, initialValues]);

  useEffect(() => {
    formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldProps = useMemo(() => {
    return {
      formik,
      translate: t,
      xs: 6,
      sm: 4,
      md: 3,
    };
  }, [formik, t]);

  return (
    <Form className="mt-4" onSubmit={formik.handleSubmit}>
      <WrapperSearch>
        <FormikControlRenaveAtom
          formik={formik}
          xs={8}
          sm={5}
          md={6}
          property={"plate"}
          type="search-submit"
          label={t("PlateAndChassi")}
          mask={MaskHelper.PlateBrChassi}
          placeholderChar={"\u2000"}
          func={(v: string) => v.toUpperCase()}
        />

        <FormikControlRenaveAtom
          {...fieldProps}
          property="startDate"
          label={t("StartDate")}
          type="date"
        />

        <FormikControlRenaveAtom
          {...fieldProps}
          property="finalDate"
          label={t("FinalDate")}
          type="date"
        />

        <Form.Group
          as={Col}
          xs={4}
          sm={3}
          md={2}
          className="pt-4 mt-1 text-center"
        >
          <ButtonPrimary
            type="submit"
            sizex="sm"
            sizey="thin"
            loading={formik.isSubmitting}
          >
            {t("OK")}
          </ButtonPrimary>
        </Form.Group>

        <Form.Group
          as={Col}
          xs={4}
          sm={3}
          md={2}
          className="pt-4 mt-1 text-center"
        >
          <CleanFiltersDiv
            onClick={formik.isSubmitting ? undefined : handleCleanFilters}
          >
            {t("CleanFilters")}
          </CleanFiltersDiv>
        </Form.Group>
      </WrapperSearch>
    </Form>
  );
};
