import { Combo, Translator } from "c4u-web-components";
import { PurchaseStatusEnum } from "../models";

export class PaymentStatusHelper {
  static getStatusName(status: PurchaseStatusEnum, t: Translator) {
    switch (status) {
      case PurchaseStatusEnum.Approved:
        return t("Approved");
      case PurchaseStatusEnum.Pending:
        return t("Pending");
      case PurchaseStatusEnum.Refused:
        return t("Refused");
      default:
        return t("");
    }
  }

  static getStatusCombos(t: Translator) {
    return Object.entries(PurchaseStatusEnum)
      .filter(([k, _]) => !isNaN(parseInt(k)))
      .map(
        ([k, _]) =>
          new Combo({
            title: this.getStatusName(parseInt(k), t),
            value: k,
          })
      );
  }
}
