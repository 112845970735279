import { CoxIcon, SpinnerCenteredAtom } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useRenave,
  useRenaveContext,
  useSessionContext,
} from "../../../../hooks";
import {
  CreditPackTypeEnum,
  ICreditPacks,
  IGetPacksResponse,
} from "../../../../models";
import { CreditPacksMolecule } from "../../../molecules";
import {
  BoxTheButton,
  CreditPurchaseOption,
  WrapperCardDiv,
} from "./credit-acquisition-packs.organism.style";

interface IProps {
  displaysPaymentForms: boolean;
  setDisplaysPaymentForms: (displaysPay: boolean) => void;
  selectedCard: number | null;
  setSelectedCard: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedPackValue: (value: number | null) => void;
}

interface IPacksSectionData {
  title: string;
  cards: ICreditPacks[];
  type: CreditPackTypeEnum;
}

export const CreditAcquisitionPacksOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { userContext } = useRenaveContext();

  const [packs, setPacks] = useState<IPacksSectionData[]>();
  const [selectedPacks, setSelectedPacks] = useState<CreditPackTypeEnum>(
    CreditPackTypeEnum.zeroKm
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { getPacks } = useRenave();

  const { showGenericErrorModal } = useSessionContext();

  const pushPacks = useCallback(
    (
      data: IGetPacksResponse,
      packsLocal: IPacksSectionData[],
      packsType: CreditPackTypeEnum,
      sectionTitle: string
    ) => {
      const filteredPacks = data.packs.filter((f) => f.type === packsType);
      if (filteredPacks.length)
        packsLocal.push({
          title: sectionTitle,
          cards: filteredPacks,
          type: packsType,
        });
    },
    []
  );

  const handleGetPacks = useCallback(async () => {
    setIsSubmitting(true);
    getPacks()
      .then((data) => {
        const packsLocal: IPacksSectionData[] = [];

        pushPacks(
          data,
          packsLocal,
          CreditPackTypeEnum.zeroKm,
          t("ZeroKmPacksSectionTitle")
        );

        pushPacks(
          data,
          packsLocal,
          CreditPackTypeEnum.usedVehicles,
          t("UsedVehiclesPacksSectionTitle")
        );

        pushPacks(
          data,
          packsLocal,
          CreditPackTypeEnum.transferDetran,
          t("TransferDetranPacksSectionTitle")
        );

        setPacks(packsLocal);
      })
      .catch(() => {
        showGenericErrorModal(
          t("GenericErrorMsg"),
          null,
          t("Error loading packs"),
          t("Error")
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [getPacks, pushPacks, showGenericErrorModal, t]);

  const onClickCallback = useCallback(
    (packs: CreditPackTypeEnum) => {
      setSelectedPacks(packs);
      props.setDisplaysPaymentForms(false);
    },
    [props]
  );

  useEffect(() => {
    props.setSelectedCard(null);
    props.setSelectedPackValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPacks]);

  useEffect(() => {
    handleGetPacks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WrapperCardDiv>
      <BoxTheButton>
        <CreditPurchaseOption
          selectButton={selectedPacks === CreditPackTypeEnum.zeroKm}
          onClick={() => onClickCallback(CreditPackTypeEnum.zeroKm)}
          type="button"
        >
          {" "}
          <CoxIcon
            fillColor={
              selectedPacks === CreditPackTypeEnum.zeroKm
                ? "#FFFFFF"
                : "#3A5693"
            }
            name="car-selection"
            width={20}
          />
          {t("ZeroKm")}
        </CreditPurchaseOption>
        <CreditPurchaseOption
          selectButton={selectedPacks === CreditPackTypeEnum.usedVehicles}
          onClick={() => onClickCallback(CreditPackTypeEnum.usedVehicles)}
          type="button"
        >
          {" "}
          <CoxIcon
            fillColor={
              selectedPacks === CreditPackTypeEnum.usedVehicles
                ? "#FFFFFF"
                : "#3A5693"
            }
            name="reload"
            width={16}
          />
          {t("Used")}
        </CreditPurchaseOption>
        {userContext?.dealership.address.state === "SP" && (
          <CreditPurchaseOption
            selectButton={selectedPacks === CreditPackTypeEnum.transferDetran}
            onClick={() => onClickCallback(CreditPackTypeEnum.transferDetran)}
            type="button"
          >
            {" "}
            <CoxIcon
              fillColor={
                selectedPacks === CreditPackTypeEnum.transferDetran
                  ? "#FFFFFF"
                  : "#3A5693"
              }
              name="bidirectional-arrows"
              width={19}
            />
            {t("Transfer")}
          </CreditPurchaseOption>
        )}
      </BoxTheButton>

      {isSubmitting ? (
        <SpinnerCenteredAtom classNames="mx-auto" />
      ) : (
        packs?.length &&
        selectedPacks &&
        packs.map(
          (pack, i) =>
            !!pack.cards.length &&
            pack.type === selectedPacks && (
              <>
                <CreditPacksMolecule
                  title={pack.title}
                  packs={pack.cards}
                  displaysPaymentForms={props.displaysPaymentForms}
                  setDisplaysPaymentForms={props.setDisplaysPaymentForms}
                  selectedCard={props.selectedCard}
                  setSelectedCard={props.setSelectedCard}
                  setSelectedPackValue={props.setSelectedPackValue}
                />
              </>
            )
        )
      )}
    </WrapperCardDiv>
  );
};
