/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LocalStorageKeys, paths } from "../../../constants";
import { useRenave, useRenaveContext } from "../../../hooks";
import {
  GetVehicleRequest,
  IGetVehicleRequest,
  IGetVehicleResponse,
  IPagedResponseBase,
  RenaveMenuStepsEnum,
} from "../../../models";
import { HeaderTitleMolecule } from "../../molecules";
import {
  BalanceAndCreditsOrganism,
  MyStockHeaderOrganism,
  MyStockListOrganism,
} from "../../organisms";
import { GradientHR } from "../shared/shared.page.style";

export const MyStockPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    setMenuSelectedStepContext,
    setMenuCurrentStepContext,
    setRestrictionContext,
    setKbbVehicleContext,
    setVehicleEntryContext,
    setVehicleDanfeContext,
    setVehicleCheckoutContext,
    setDiagnosisListContext,
    setDocumentContext,
    setZapayPaymentStatusContext,
    setRenaveVehicleFetchedContext,
  } = useRenaveContext();

  const [myVehicles, setMyVehicles] = useState<IGetVehicleResponse[]>();
  const [paginationData, setPaginationData] = useState<IPagedResponseBase>();
  const [pageNumber, setPageNumber] = useState<{
    number: number;
    isFilter: boolean;
  }>();
  const [goUpdate, setGoUpdate] = useState(false);
  const [filterValues, setFilterValues] = useState<IGetVehicleRequest>();
  const { getRenaveStock } = useRenave()

  useEffect(() => {
    if (goUpdate) setGoUpdate(false);
  }, [goUpdate]);

  const handlePageNumberChange = (number: number, isFilter: boolean = false) => {
    setPageNumber({
      number,
      isFilter,
    });
  };

  useEffect(() => {
    setMenuSelectedStepContext(undefined);
    setMenuCurrentStepContext(undefined);
    setRestrictionContext(undefined);
    setKbbVehicleContext(undefined);
    setVehicleEntryContext(undefined);
    setVehicleDanfeContext(undefined);
    setVehicleCheckoutContext(undefined);
    setDiagnosisListContext(undefined);
    setDocumentContext(undefined);
    setZapayPaymentStatusContext(undefined);
    setRenaveVehicleFetchedContext(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function firstRender() {
        const initialValues = {
          search: "",
          page: 1,
          pageSize: 10,
          startDate: "",
          finishDate: "",
          step: undefined,
          isUsed: true,
          isZeroKm: true,
        };
        const request = new GetVehicleRequest({
          ...initialValues,
          updateZeroKm: false,
        });

        const { data, ...paginationData } = await getRenaveStock(request);
        setMyVehicles(data);
        setFilterValues(initialValues);
        setPaginationData(paginationData);
    }
    firstRender();
  }, []);

  useEffect(() => {
    const validationFilter = async () => {
      if (filterValues && pageNumber && !pageNumber.isFilter) {
        setMyVehicles(undefined);
          const updateZeroKm =
            localStorage.getItem(LocalStorageKeys.UpdateZeroKm) === "true";
          const request = new GetVehicleRequest({
            ...filterValues,
            page: pageNumber.number,
            updateZeroKm,
          });

          const { data, ...paginationData } = await getRenaveStock(request);
          setMyVehicles(data);
          setPaginationData(paginationData);
        }
      };
      validationFilter();
  }, [pageNumber]);

  return (
    <>
      <HeaderTitleMolecule title={t("MyStockPageTitle")} disableReturnButton />

      <MyStockHeaderOrganism
        setMyVehicles={setMyVehicles}
        setPaginationData={setPaginationData}
        setPageNumber={handlePageNumberChange}
        goUpdate={goUpdate}
        setGoUpdate={setGoUpdate}
        setFilterValues={value => setFilterValues(value)}
      />

      <GradientHR />

      <BalanceAndCreditsOrganism
        navigateEntryVehicle={() => {
          setMenuCurrentStepContext(RenaveMenuStepsEnum.Aptness);
          history.push(paths.aptnessQuery());
        }}
        goUpdate={goUpdate}
      />

      <MyStockListOrganism
        myVehicles={myVehicles}
        paginationData={paginationData}
        setPageNumber={handlePageNumberChange}
        setGoUpdate={setGoUpdate}
      />
    </>
  );
};
