import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PurchaseOrderHeaderList } from "./purchase-order-header.molecule.style";

export const PurchaseOrderHeaderMolecule: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Col>
      <Row className="text-center">
        <Col sm={2}>
          <PurchaseOrderHeaderList>{t("OrderId")}</PurchaseOrderHeaderList>
        </Col>
        <Col sm={1}>
          <PurchaseOrderHeaderList>{t("DateHour")}</PurchaseOrderHeaderList>
        </Col>
        <Col sm={2}>
          <PurchaseOrderHeaderList>{t("PurchaseType")}</PurchaseOrderHeaderList>
        </Col>
        <Col sm={1}>
          <PurchaseOrderHeaderList>{t("Pack")}</PurchaseOrderHeaderList>
        </Col>
        <Col sm={3}>
          <PurchaseOrderHeaderList>
            {t("PaymentMethod")}
          </PurchaseOrderHeaderList>
        </Col>
        <Col sm={1}>
          <PurchaseOrderHeaderList>
            {t("StatusPayment")}
          </PurchaseOrderHeaderList>
        </Col>
        <Col sm={2}>
          <PurchaseOrderHeaderList>{t("AmountPaid")}</PurchaseOrderHeaderList>
        </Col>
      </Row>
    </Col>
  );
};
