import { ButtonPrimary } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../constants";
import { useRenave, useSessionContext } from "../../../../hooks";
import {
  ICreditAcquisitionForm,
  IFormikSetErrors,
  PaymentMethodEnum,
  PostPacksRequest,
} from "../../../../models";
import {
  BankSlipModalMolecule,
  CreditAcquisitionFormMolecule,
  GenerateBankSlipModalMolecule,
} from "../../../molecules";
import {
  BoxPayNow,
  WrapperCreditAcquisition,
} from "./credit-acquisition-payment.organism.style";

interface IProps {
  packId: number;
  packValue: number;
}

export const CreditAcquisitionPaymentOrganism: React.FC<IProps> = ({
  packId,
  packValue
}) => {
  const { t } = useTranslation();
  const { postPacks } = useRenave();
  const {
    handleFormikException,
    showGenericSuccessModal,
    showGenericErrorModal,
  } = useSessionContext();

  const [goSubmit, setGoSubmit] = useState(false);
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodEnum>();
  const [isBankSlipModalVisible, setIsBankSlipModalVisible] = useState(false);
  const [
    isProblemOpenBankSlipModalVisible,
    setIsProblemOpenBankSlipModalVisible,
  ] = useState(false);
  const [urlBankSplit, setUrlBankSplit] = useState<string>();

  console.log(urlBankSplit);

  const handleSubmitCreditPayment = useCallback(
    async (
      values: ICreditAcquisitionForm,
      { setErrors }: IFormikSetErrors<ICreditAcquisitionForm>
    ) => {
      try {
        if (selectedPaymentMethod !== undefined) {
          const { cardholder, cardNumber, cvv, expiration, dueDate } = values;
          const payload = new PostPacksRequest({
            packId: packId,
            cardholder: cardholder ?? null,
            cardNumber: cardNumber ?? null,
            cvv: cvv ?? null,
            expiration: expiration ?? null,
            dueDate: dueDate ?? null,
            operationType: selectedPaymentMethod,
            value: packValue
          });

          const data = await postPacks(payload);

          if (data.result) {
            if (selectedPaymentMethod === PaymentMethodEnum.bankSlip) {
              window.open(data.bankSlipURL, "_blank", "noopener,noreferrer");
              setUrlBankSplit(data.bankSlipURL);
              setIsProblemOpenBankSlipModalVisible(true);
            } else {
              showGenericSuccessModal(
                " ",
                () => history.push(paths.home()),
                t("PaymentAccept")
              );
            }
          } else {
            const errorMessage =
              selectedPaymentMethod === PaymentMethodEnum.bankSlip
                ? t("PaymentFailedBankSlip")
                : t("PaymentFailed");
            showGenericErrorModal(errorMessage, null, t("PaymentFailedTitle"));
          }
        }
      } catch (e) {
        handleFormikException(e, setErrors);
      }
    },
    [
      selectedPaymentMethod,
      packId,
      packValue,
      postPacks,
      showGenericSuccessModal,
      t,
      history,
      showGenericErrorModal,
      handleFormikException,
    ]
  );

  useEffect(() => {
    if (goSubmit) setGoSubmit(false);
  }, [goSubmit]);

  return (
    <WrapperCreditAcquisition>
      <CreditAcquisitionFormMolecule
        goSubmit={goSubmit}
        onSubmit={handleSubmitCreditPayment}
        setIsSubmitting={setIsSubmitting}
        packId={packId}
        selectedPaymentMethod={selectedPaymentMethod}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
        openBankSlipModalHandle={(value) => setIsBankSlipModalVisible(value)}
      />
      {selectedPaymentMethod === PaymentMethodEnum.creditCard && (
        <BoxPayNow>
          <ButtonPrimary
            sizex="md"
            loading={isSubmitting}
            onClick={() => {
              setGoSubmit(true);
            }}
          >
            {t("PayNow")}
          </ButtonPrimary>
        </BoxPayNow>
      )}
      {selectedPaymentMethod === PaymentMethodEnum.bankSlip && (
        <GenerateBankSlipModalMolecule
          isModalVisible={isBankSlipModalVisible}
          setIsModalVisible={(value) => setIsBankSlipModalVisible(value)}
          onSubmit={handleSubmitCreditPayment}
        />
      )}

      {isProblemOpenBankSlipModalVisible && (
        <BankSlipModalMolecule
          isModalVisible={isProblemOpenBankSlipModalVisible}
          setIsModalVisible={(value) =>
            setIsProblemOpenBankSlipModalVisible(value)
          }
          bankSlipURL={urlBankSplit}
        />
      )}
    </WrapperCreditAcquisition>
  );
};
