import styled from "styled-components";

const CreditStatementTextStyleBasePrimary = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  padding: 10px;
`;

const CreditStatementTextStyleBaseSecond = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 400;
  padding: 10px;
`;

export const CreditStatementWrapper = styled.div`
  background: #f2f2f3;
  border-radius: 10px;
  box-shadow: 0px 8px 10px rgb(0 0 0 / 8%);
`;

export const CreditStatementTextPrimary = styled(CreditStatementTextStyleBasePrimary)`
  color: ${(props) => props.theme.colors.BlueNavy};
  font-size: 12px;
  line-height: 20px;
`;

export const CreditStatementTextSecond = styled(CreditStatementTextStyleBaseSecond)`
  color: ${(props) => props.theme.colors.BlueNavy};
  font-size: 12px;
  line-height: 20px;
`;

export const CreditStatementTextMargin = styled(CreditStatementTextStyleBaseSecond)`
  color: ${(props) => props.theme.colors.BlueNavy};
  font-size: 12px;
  line-height: 20px;
  margin: 10px 0 0 0;
`;

export const CreditStatementNegativeMoneyText = styled(CreditStatementTextStyleBasePrimary)`
  color: #0799f8;
  font-size: 12px;
  line-height: 20px;
  margin: 10px 0 0 0;
`;
