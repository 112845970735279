import { TypesHelper } from "c4u-web-components";

export interface IGetCreditStatementRequest {
  startDate: Date | string;
  finalDate: Date | string;
  plate?: string;
}

export class GetCreditStatementRequest {
  startDate: string | null;
  finalDate: string | null;
  plate: string | null;

  constructor({ startDate, finalDate, plate }: IGetCreditStatementRequest) {
    this.startDate = TypesHelper.toStringNull(startDate);
    this.finalDate = TypesHelper.toStringNull(finalDate);
    this.plate = TypesHelper.toStringNull(plate);
  }
}
