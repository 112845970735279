import styled from "styled-components";

export const PurchaseOrderHeaderList = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  color: #a8aaac;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
`;
