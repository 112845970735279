import { Combo, Translator } from "c4u-web-components";
import { PaymentMethodEnum } from "../models";

export class PaymentMethodHelper {
  static getStatusName(status: PaymentMethodEnum, t: Translator) {
    switch (status) {
      case PaymentMethodEnum.bankSlip:
        return t("bankSlip");
      case PaymentMethodEnum.creditCard:
        return t("creditCard");
      default:
        return t("");
    }
  }

  static getStatusCombos(t: Translator) {
    return Object.entries(PaymentMethodEnum)
      .filter(([k, _]) => !isNaN(parseInt(k)))
      .map(
        ([k, _]) =>
          new Combo({
            title: this.getStatusName(parseInt(k), t),
            value: k,
          })
      );
  }
}
