import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { HeaderTitleMolecule } from "../../molecules";
import { CreditStatementOrganism } from "../../organisms";
import { GradientHR } from "../shared/shared.page.style";

export const CreditStatementPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <HeaderTitleMolecule
        title={t("CreditStatement")}
        returnButtonActionOverride={() => history.push(paths.myStock())}
        haveButtonsCreditAcquisitionPacksAndOrderList={true}
        isScreenCreditStatement={true}
      />

      <GradientHR />

      <CreditStatementOrganism />
    </>
  );
};
