import styled from "styled-components";

export const WrapperSearch = styled.div`
  display: flex;
  justify-content: flex-start;

  .form-control {
    height: 41px;
  }

  .btn-link {
    height: 40px;
  }
`;

export const CleanFiltersDiv = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;

  text-transform: uppercase;
  color: ${(props) => props.theme.colors.BlueNavy};

  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  opacity: ${(props) => (props.onClick ? 1 : 0.4)};
  margin: 10px 0 0 0;

  :hover {
    text-decoration-line: underline;
  }
`;
