import { CreditPackCardAtom, NumberHelper } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { ICreditPacks } from "../../../models";
import {
  CreditPacksSectionWrapper,
  CreditPacksWrapper,
} from "./credit-packs.molecule.style";

interface IProps {
  title: string;
  packs: ICreditPacks[];
  displaysPaymentForms: boolean;
  setDisplaysPaymentForms: (displaysPay: boolean) => void;
  selectedCard: number | null;
  setSelectedCard: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedPackValue: (value: number | null) => void;
}

export const CreditPacksMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const handleChoose = (id: number, value: number) => {
    props.setSelectedCard((prevSelected) => (prevSelected === id ? null : id));
    props.setSelectedPackValue(value);
    props.setDisplaysPaymentForms(true);
  };

  return (
    <CreditPacksSectionWrapper>
      <CreditPacksWrapper>
        {props.packs.map((pack, i) => (
          <CreditPackCardAtom
            key={`pack-${props.title}-${i}`}
            id={pack.id}
            title={pack.description}
            valueLabel={t("Value") + ":"}
            valueText={t("{{value}} ValuePlan", {
              value: NumberHelper.toFormatString(pack.value, 2),
            })}
            buttonText={t("Buy")}
            onChoose={() => {
              handleChoose(pack.id, pack.value);
            }}
            selectedCard={props.selectedCard}
          />
        ))}
      </CreditPacksWrapper>
    </CreditPacksSectionWrapper>
  );
};
