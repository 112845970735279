import { ButtonSecondary, theme } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { paths } from "../../../../constants";
import { useRenave } from "../../../../hooks";
import { IBreadcrumbs } from "../../../../models";
import { BreadcrumbsAtom, CreditOrderNavigationAtom } from "../../../atoms";
import { BalanceSummaryMolecule } from "../../balance-summary/balance-summary.molecule";
import {
  AllCapsButtonTertiary,
  BackButton,
  BoxButtonsCreditStatementAndOrderList,
  HeaderTitleWrapper,
  ScreenCreditStatement,
  TitleDiv,
} from "./header-title.molecule.style";

interface IProps {
  title: string;
  navigationButtonText?: string;
  navigationButtonAction?: () => void;
  returnButtonActionOverride?: () => void;
  disableReturnButton?: boolean;
  hideReturnButton?: boolean;
  haveButtonsCreditStatementAndOrderList?: boolean;
  haveButtonsCreditAcquisitionPacksAndOrderList?: boolean;
  isScreenCreditStatement?: boolean;
}

export const HeaderTitleMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const breadcrumbs: IBreadcrumbs[] = [
    { text: t("Home"), path: process.env.REACT_APP_BASE_URL, externally: true },
    { text: t("Renave"), path: paths.myStock() },
    { text: props.title },
  ];

  const hideReturnButton = props.hideReturnButton || props.disableReturnButton;

  const [goUpdate, setGoUpdate] = useState(false);
  const { getDailyBalance } = useRenave();

  useEffect(() => {
    setGoUpdate(true);
  }, [goUpdate]);

  return (
    <HeaderTitleWrapper>
      <BreadcrumbsAtom
        breadcrumbs={breadcrumbs}
        backgroundColor={theme.colors.Background}
      />
      <Row>
        {!hideReturnButton && (
          <BackButton>
            <ButtonSecondary
              sizex={"sm"}
              sizey={"thin"}
              onClick={
                props.returnButtonActionOverride ?? (() => history.go(-1))
              }
              disabled={!!props.disableReturnButton}
            >
              {t("Back")}
            </ButtonSecondary>
          </BackButton>
        )}

        <Col xs className={"my-3 my-md-0"}>
          <TitleDiv>{props.title}</TitleDiv>
        </Col>
        <Col xs={"auto"} className="text-right center-align-vertical ml-auto">
          {props.navigationButtonText && props.navigationButtonAction && (
            <AllCapsButtonTertiary
              sizex={"md"}
              sizey={"thin"}
              onClick={props.navigationButtonAction}
            >
              {props.navigationButtonText}
            </AllCapsButtonTertiary>
          )}
        </Col>

        {props.haveButtonsCreditStatementAndOrderList && (
          <ScreenCreditStatement>
            <BoxButtonsCreditStatementAndOrderList
              isScreenCreditStatementOrOrderList={true}
            >
              <CreditOrderNavigationAtom screenOrderList={true} />
            </BoxButtonsCreditStatementAndOrderList>
            <BalanceSummaryMolecule
              goUpdate={goUpdate}
              getBalanceCallback={getDailyBalance}
              size="sm"
              thisScreenNeedsBuyCreditButton={true}
              isScreenCreditStatementOrOrderList={true}
            />
          </ScreenCreditStatement>
        )}

        {props.haveButtonsCreditAcquisitionPacksAndOrderList && (
          <ScreenCreditStatement>
            <BoxButtonsCreditStatementAndOrderList
              isScreenCreditStatementOrOrderList={true}
            >
              <CreditOrderNavigationAtom screenCreditStatement={true} />
            </BoxButtonsCreditStatementAndOrderList>

            <BalanceSummaryMolecule
              goUpdate={goUpdate}
              getBalanceCallback={getDailyBalance}
              size="sm"
              thisScreenNeedsBuyCreditButton={true}
              isScreenCreditStatementOrOrderList={true}
            />
          </ScreenCreditStatement>
        )}
      </Row>
    </HeaderTitleWrapper>
  );
};
