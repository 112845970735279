import { CoxIcon } from "c4u-web-components";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { paths } from "../../../constants";
import {
  CreditAndOrdersWrapper,
  Divider,
} from "./credit-order-navigation.atom.style";

interface IProps {
  screenDefault?: boolean;
  screenCreditStatement?: boolean;
  screenOrderList?: boolean;
}

export const CreditOrderNavigationAtom: React.FC<IProps> = ({
  screenDefault,
  screenCreditStatement,
  screenOrderList,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {screenDefault === true && (
        <CreditAndOrdersWrapper>
          <Link to={paths.creditStatement()}>
            <CoxIcon
              name="registrations"
              fillColor="#0585C8"
              width={16}
              height={16}
            />
            {t("CreditStatement")}
          </Link>
          <Divider />
          <Link to={paths.orderList()}>
            <CoxIcon name="book" viewBox="0 0 16 12" />
            {t("OrderList")}
          </Link>
        </CreditAndOrdersWrapper>
      )}

      {screenCreditStatement === true && (
        <CreditAndOrdersWrapper>
          <Link to={paths.CreditAcquisitionPacksPage()}>
            <CoxIcon
              name="dolar-circle-v2"
              fillColor="#0585C8"
              width={16}
              height={16}
            />
            {t("CreditAcquisition")}
          </Link>
          <Divider />
          <Link to={paths.orderList()}>
            <CoxIcon name="book" viewBox="0 0 16 12" />
            {t("OrderList")}
          </Link>
        </CreditAndOrdersWrapper>
      )}

      {screenOrderList === true && (
        <CreditAndOrdersWrapper>
          <Link to={paths.CreditAcquisitionPacksPage()}>
            <CoxIcon
              name="dolar-circle-v2"
              fillColor="#0585C8"
              width={16}
              height={16}
            />
            {t("CreditAcquisition")}
          </Link>
          <Divider />
          <Link to={paths.creditStatement()}>
            <CoxIcon
              name="registrations"
              fillColor="#0585C8"
              width={16}
              height={16}
            />
            {t("CreditStatement")}
          </Link>
        </CreditAndOrdersWrapper>
      )}
    </>
  );
};
