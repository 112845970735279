import styled from "styled-components";

const OrderListTextStyleBasePrimary = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  padding: 10px;
`;

const OrderListTextStyleBaseSecond = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 400;
  padding: 10px 0;
`;

export const OrderListWrapper = styled.div`
  background: #f2f2f3;
  border-radius: 10px;
  box-shadow: 0px 8px 10px rgb(0 0 0 / 8%);
`;

export const OrderListTextPrimary = styled(OrderListTextStyleBasePrimary)`
  color: ${(props) => props.theme.colors.BlueNavy};
  font-size: 12px;
  line-height: 20px;
  margin: 10px 0 0 0;
`;

export const OrderListTextSecond = styled(OrderListTextStyleBaseSecond)`
  color: ${(props) => props.theme.colors.BlueNavy};
  font-size: 12px;
  line-height: 20px;
`;

export const OrderListTextMargin = styled(OrderListTextStyleBaseSecond)`
  color: ${(props) => props.theme.colors.BlueNavy};
  font-size: 12px;
  line-height: 20px;
  margin: 10px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-align: center;
  white-space: nowrap;
`;

export const OrderListNegativeMoneyText = styled(OrderListTextStyleBasePrimary)`
  color: #0799f8;
  font-size: 12px;
  line-height: 20px;
  margin: 10px 0 0 0;
`;

export const ButtonPaymentMethod = styled.button`
  background-color: rgb(242, 242, 243);
  border: none;
  color: rgb(31, 62, 116);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const StatusIndicator = styled.span<{ status: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.status};
  padding: 6px;
`;
