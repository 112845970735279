export enum RenaveStatusEnum {
  Created, // -> Veículo Criado
  Aptness, // -> Entrada veículo
  CheckInVehicle, // -> Danfe de entrada
  CheckInDanfe, // -> Aguardando Detran
  CheckedIn, // -> Em estoque
  CheckOutVehicle, // -> Danfe de saída
  CheckOutDanfe, // Aguardando transferência/venda
  CheckedOut, // -> Fora de estoque
  Cancelled, // -> Cancelado
  PendingZeroKm, // -> Entrada veículo 0 km
  RejectedZeroKm, // Rejeitado 0km
  CheckedInZeroKm, // -> Em estoque 0km
  CheckOutVehicleZeroKm, // -> Aguardando transferência/venda 0km
  CheckedOutZeroKm, // -> Fora de estoque 0km
  ReturnedZeroKm, // Retornado para a montadora
  AptnessRestriction, // -> Aptidão com restrição,
}
