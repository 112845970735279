import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IGetCreditStatementResponse } from "../../../models";
import {
  CreditStatementNegativeMoneyText,
  CreditStatementTextMargin,
  CreditStatementTextPrimary,
  CreditStatementTextSecond,
  CreditStatementWrapper
} from "./credit-statement-data-list.molecule.style";

interface IProps {
  creditStatementData?: IGetCreditStatementResponse;
}

export const CreditStatementDataListMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { creditStatementData } = props;

  return (
    <Col>
      <CreditStatementWrapper>
        <Row className="mt-3 text-center">
          {creditStatementData === undefined ? (
            <Col sm={12}>
              <CreditStatementTextSecond>
                {t("NoTransactionsFound")}
              </CreditStatementTextSecond>
            </Col>
          ) : (
            <>
              <Col sm={2}>
                <CreditStatementTextMargin>
                  {creditStatementData.date}
                </CreditStatementTextMargin>
              </Col>

              <Col sm={4}>
                <CreditStatementTextPrimary>
                  {creditStatementData.description}
                </CreditStatementTextPrimary>
              </Col>

              <Col sm={3}>
                <CreditStatementTextMargin>
                  {creditStatementData.plate}
                </CreditStatementTextMargin>
              </Col>

              <Col sm={3}>
                <CreditStatementNegativeMoneyText>
                  {"- "}
                  {creditStatementData.stocks}
                </CreditStatementNegativeMoneyText>
              </Col>
            </>
          )}
        </Row>
      </CreditStatementWrapper>
    </Col>
  );
};
