import { CoxIcon } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useRenave } from "../../../../hooks";
import { BalanceSummaryMolecule } from "../../../molecules";
import {
  BalanceAndCreditsWrapper,
  EntryVehicleButton,
} from "./balance-and-credits.organism.style";
import { CreditOrderNavigationAtom } from "../../../atoms";

interface IProps {
  goUpdate: boolean;
  navigateEntryVehicle: () => void;
}

export const BalanceAndCreditsOrganism: React.FC<IProps> = ({
  goUpdate,
  navigateEntryVehicle,
}) => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  const reduceButton = useMediaQuery({ maxWidth: 991 });

  const { getDailyBalance } = useRenave();

  return (
    <>
      <BalanceAndCreditsWrapper>
        <BalanceSummaryMolecule
          goUpdate={goUpdate}
          getBalanceCallback={getDailyBalance}
          size={isSmallScreen ? "sm" : "md"}
        />
        <EntryVehicleButton onClick={navigateEntryVehicle}>
          <CoxIcon name="car-add" fillColor="#FFFFFF" />
          {!reduceButton && t("AddNewVehicle")}
        </EntryVehicleButton>
      </BalanceAndCreditsWrapper>
      <CreditOrderNavigationAtom screenDefault={true} />
    </>
  );
};
